import {
  CSSWithMultiValues,
  RecursiveCSSObject,
} from "@chakra-ui/styled-system"

import { Dict } from "@chakra-ui/utils"
import { transparentize } from "@chakra-ui/theme-tools"

const variantPrimary = (
  props: Dict,
): RecursiveCSSObject<CSSWithMultiValues> => {
  const transparentColor = transparentize("gray.300", 0.4)(props.theme)
  const disabled = {
    opacity: "0.5",
    color: transparentColor,
    bg: "blue.100",

  }
  return {
    color: "white",
    bg: "blue.100",
    border: "1px double transparent!important",
    backgroundImage: "linear-gradient(#202231, #202231), linear-gradient(90deg, #0077cc, #660099)!important",
    backgroundOrigin: "border-box!important",
    backgroundClip: "padding-box, border-box!important",
    _hover: {
      color: "white",
      bg: "purple.500",
      _disabled: disabled,
    },
    _active: {
      color: "white",
      bg: "purple.500",
    },
    _disabled: disabled,
  }
}

const variants = {
  primary: variantPrimary,
}
export default { variants }
