import "./TopMenu.scss"
import React, { ReactElement } from "react"
import { Link } from "react-router-dom"
import Web3Status from "./Web3Status"
import classNames from "classnames"
import logo from "../assets/icons/logo.png"
import { useTranslation } from "react-i18next"
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button
} from "@chakra-ui/react"
import { ChevronDownIcon, InfoOutlineIcon } from '@chakra-ui/icons'
import { FaGithub, FaTwitter, FaMedium, FaDiscord, FaTelegram, FaExclamationCircle } from "react-icons/fa";
import { SiGitbook } from "react-icons/si";


interface Props {
  activeTab: string
}

function TopMenu({ activeTab }: Props): ReactElement {
  const { t } = useTranslation()
  const handleClick = () => {
    window.location.href = window.location.origin + "/#/risk";
  }
  const handleExternalClick = (url: string) => {
    window.open(url, '_blank');
  }
  return (
    <header className="top">
      <h1>
        <Link to="/">
          <img className="logo" alt="logo" src={logo} />
        </Link>
      </h1>

      <ul className="nav">
        <li>
          <Link to="/" className={classNames({ active: activeTab === "swap" })}>
            {t("swap")}
          </Link>
        </li>
        <li>
          <Link
            to="/pools"
            className={classNames({
              active:
                activeTab === "pools" ||
                activeTab === "deposit" ||
                activeTab === "withdraw",
            })}
          >
            {t("pools")}
          </Link>
        </li>
        <li>
          <a onClick={() => handleExternalClick('https://iotex.farm.mcn.ventures/')}>Farm</a>
        </li>
        <Menu>
          <MenuButton className="topNavMoreButton" variant="outline" as={Button} rightIcon={<ChevronDownIcon />}>
            More
          </MenuButton>
          <MenuList>
            <MenuItem icon={<FaExclamationCircle />} onClick={() => handleExternalClick('https://minmaxfinance.gitbook.io/minmax-finance/risk')}>
              Risk
            </MenuItem>
            <MenuItem icon={<InfoOutlineIcon />} onClick={() => handleExternalClick('#')}>Documentations</MenuItem>
            <MenuItem icon={<FaGithub />} onClick={() => handleExternalClick('https://github.com/minmaxfinance')}>Github</MenuItem>
            <MenuItem icon={<SiGitbook />} onClick={() => handleExternalClick('https://minmaxfinance.gitbook.io/minmax-finance/')}>Gitbook</MenuItem>
            <MenuItem icon={<FaMedium />} onClick={() => handleExternalClick('#')}>Medium</MenuItem>
            <MenuItem icon={<FaDiscord />} onClick={() => handleExternalClick('https://discord.gg/yNWTqDm5gu')}>Discord</MenuItem>
            <MenuItem icon={<FaTwitter />} onClick={() => handleExternalClick('https://twitter.com/minmaxfinance')}>Twitter</MenuItem>
            <MenuItem icon={<FaTelegram />} onClick={() => handleExternalClick('#')}>Telegram</MenuItem>
          </MenuList>
        </Menu>
      </ul>
      <Web3Status />
    </header>
  )
}

export default TopMenu
